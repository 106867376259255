import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Noria Privacy Policy</h1>
      <p>Last updated: January 20, 2025</p>
      
      <h2>1. Introduction</h2>
      <p>Noria ("we", "our", or "us") respects your privacy and is committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you take our online assessment and tell you about your privacy rights and how the law protects you.</p>
      
      <h2>2. The data we collect about you</h2>
      <p>We collect and process your email address when you submit it to receive your assessment results.</p>
      
      <h2>3. How we use your personal data</h2>
      <p>We use your email address to:</p>
      <ul>
        <li>Send you your assessment results</li>
        <li>Send you marketing communications about our products and services</li>
      </ul>
      <p>We do not store or use your personal entries in the quiz.</p>
      
      <h2>4. Data security</h2>
      <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.</p>
      
      <h2>5. Data retention</h2>
      <p>We will only retain your email address for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
      
      <h2>6. Your legal rights</h2>
      <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data and (where the lawful ground of processing is consent) to withdraw consent.</p>
      
      <h2>7. Third-party links</h2>
      <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.</p>
      
      <h2>8. Changes to the privacy policy</h2>
      <p>We keep our privacy policy under regular review. This version was last updated on September 27, 2024. Historic versions can be obtained by contacting us.</p>
      
      <h2>9. Contact us</h2>
      <p>If you have any questions about this privacy policy or our privacy practices, please contact us at: [Insert Noria's contact email or form]</p>
    </div>
  );
}

export default PrivacyPolicy;