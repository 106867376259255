import React, { useState }  from 'react';
// Import Chart and elements from chart.js
import { Chart, registerables, ArcElement, RadialLinearScale, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import './styles.css';

// Register all controllers, elements, scales, and plugins from the registerables array
Chart.register(...registerables);

function Results({ scores, quizData }) {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const categories = Object.keys(scores);
  const maxRange = quizData.quiz.questions[0].range.max;

  const bgColorList = [
    'rgba(208, 96, 71, 0.6)', // Tomato
    'rgba(201, 149, 40, 0.6)', // Ochre
    'rgba(164, 163, 122, 0.6)', // Sage
    'rgba(118, 108, 138, 0.6)', // Amethyst
    'rgba(217, 162, 127, 0.6)', // Clay
    'rgba(175, 165, 190, 0.6)', // Lavender
    'rgba(72, 134, 147, 0.6)', // Teal
    'rgba(242, 175, 133, 0.6)', // Peach
    'rgba(128, 130, 74, 0.6)', // Olive
    'rgba(188, 140, 148, 0.6)', // Dusty Rose
  ];
  const borderColorList = [
    'rgba(208, 96, 71, 1)', // Tomato
    'rgba(201, 149, 40, 1)', // Ochre
    'rgba(164, 163, 122, 1)', // Sage
    'rgba(118, 108, 138, 1)', // Amethyst
    'rgba(217, 162, 127, 1)', // Clay
    'rgba(175, 165, 190, 1)', // Lavender
    'rgba(72, 134, 147, 1)', // Teal
    'rgba(242, 175, 133, 1)', // Peach
    'rgba(128, 130, 74, 1)', // Olive
    'rgba(188, 140, 148, 1)', // Dusty Rose
  ];
  
  //function EmailForm({ onEmailSubmit }) {
    //const [email, setEmail] = useState('');
  
    const handleSubmit = async (event) => {
      window.gtag('config', 'AW-684082633');
      window.gtag('event', 'conversion', {'send_to': 'AW-684082633/UNQ0CK7i48cZEMmLmcYC'});
      
      // Add Meta Pixel tracking
      try {
        if (window.fbq) {
          window.fbq('track', 'ViewContent', {
            content_name: 'Quiz Results',
            content_category: 'Assessment Results',
            status: 'results_viewed'
          });
        }
      } catch (error) {
        console.error('Error tracking Meta event:', error);
      }
      
      event.preventDefault();
      console.log(event);
      setEmailSubmitted(true);
      
      // Calculate the average scores for each category
      const averageScores = {};
      Object.keys(scores).forEach(category => {
        const total = scores[category].reduce((acc, curr) => acc + curr, 0);
        const average = total / scores[category].length;
        averageScores[category] = average;
      });

      // Get the lowest categories for personalization
      const sortedCategories = Object.keys(averageScores)
        .map(category => ({
          category,
          score: averageScores[category]
        }))
        .sort((a, b) => a.score - b.score);

      const lowestCategories = sortedCategories
        .slice(0, 2)
        .map(item => item.category);

      try {
        // First, send to your existing endpoint


        // Then send to Zapier webhook
        const zapierResponse = await fetch('https://hooks.zapier.com/hooks/catch/20636901/2k8fyqs/', {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            scores: averageScores,
            lowestCategories: lowestCategories,
            timestamp: new Date().toISOString()
          }),
        });

        if (!zapierResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await zapierResponse.json();
        console.log(responseData);

      } catch (error) {
        console.error('Error submitting data:', error);
      }
    };
    /*
    return (
      <form onSubmit={handleSubmit} className="email-form">
        <label htmlFor="emailInput">Enter your email to see the results:</label>
        <input
          type="email"
          id="emailInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="btn email-submit-btn">Submit</button>
      </form>
    );
    */
  //}

  // Prepare data for the PolarArea chart
  const data = {
    labels: Object.keys(scores),
    datasets: [{
      label: 'Capacity Score',
      data: Object.keys(scores).map(category => {
        const categoryScores = scores[category];
        const averageScore = categoryScores.reduce((a, b) => a + b, 0) / categoryScores.length;
        return averageScore;
      }),
      backgroundColor: bgColorList,
      borderColor: borderColorList,
      borderWidth: 1,
    }]
  };

    // Data for the "Full Well" and "Empty Well" PolarArea charts
    const fullWellData = {
      labels: categories,
      datasets: [{
        data: categories.map(() => maxRange),
        backgroundColor: bgColorList,
        borderColor: borderColorList,
        borderWidth: 1,
      }]
    };
  
    const emptyWellData = {
      labels: categories,
      datasets: [{
        data: categories.map(() => 1),
        backgroundColor: bgColorList,
        borderColor: borderColorList,
        borderWidth: 1,
      }]
    };
  const options = {
    
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: quizData.quiz.questions[0].range.max
      }
    },
    plugins: {
      legend: {
        position: 'bottom', // Adjust as needed
        labels: {
          usePointStyle: false,
        },
      },
    }
  };

  const optionsSmall = {
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: quizData.quiz.questions[0].range.max
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    }
  };

// Calculate average scores for each category
const averageScores = {};
Object.keys(scores).forEach(category => {
  const total = scores[category].reduce((acc, curr) => acc + curr, 0);
  const average = total / scores[category].length;
  averageScores[category] = average;
});

// Convert averageScores to an array and sort by score
const sortedScores = Object.keys(averageScores).map(category => ({
  category,
  score: averageScores[category]
})).sort((a, b) => a.score - b.score);

  // Determine the lowest two categories, including ties for the second lowest score
  let lowestCategories = [sortedScores[0]];
  let secondLowestScore = sortedScores[1].score; // Start with the second item as the next lowest score

  for (let i = 1; i < sortedScores.length; i++) {
    if (sortedScores[i].score <= secondLowestScore) {
      lowestCategories.push(sortedScores[i]);
      secondLowestScore = sortedScores[i].score;
    } else break; // Stop if the next score is higher than the second lowest
  }





  return (
    
    <div className="results-container">
         
          {!emailSubmitted && (
      <div className="email-wall">
        <form onSubmit={handleSubmit} className="email-form">
          <div className="email-input-container">
            <label htmlFor="email">Enter your email to see your results:</label>
            <input 
              type="email" 
              id="email"  
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoFocus="autofocus"
            />
            <button type="submit">Submit</button>
          </div>
          <p className="privacy-notice">
            By submitting your email, you agree to receive marketing communications from us. 
            We respect your privacy: your quiz responses will not be stored or used, and we will never sell your information. 
            You can unsubscribe at any time. See our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> for more details.
          </p>
        </form>
      </div>
    )}

    <div className={`results-content ${emailSubmitted ? '' : 'blur'}`}></div>
      
      <h2 className="results-title">Noria Burnout Assessment Results</h2>
      <p className="big-chart-title">Hover over each segment to see its category.</p>
      <PolarArea data={data} options={options} />
      <div className="small-charts-container">
        <div className="small-chart">
          <p className="chart-title">Full Well</p>
          <PolarArea data={fullWellData} options={optionsSmall}/>
        </div>
        <div className="small-chart">
          <p className="chart-title">Empty Well</p>
          <PolarArea data={emptyWellData} options={optionsSmall}/>
          
        </div>
      </div>

      <div className="response-message">
  <p>Thank you for taking the Noria Burnout Assessment!</p>
  <p>Above is a visual of your "Innerwell." Some factors may be "full" and others may be "low." If many factors are running low, you may be at risk for burnout.</p>
  <p>Burnout is typically a combination of personal propensities and systemic/work issues. It is important to know that finding yourself in a state of depletion <em>is not your fault.</em> Systemic factors play a big part in burnout so you may need to make changes and advocate for yourself. We've included tips below! "</p>
  <p>Your most significant depleters are {
    lowestCategories.length > 1 
      ? `${lowestCategories.slice(0, -1).map(c => c.category).join(', ')}, and ${lowestCategories.slice(-1)[0].category}`
      : lowestCategories[0].category
  }.</p>
  {lowestCategories.map((category, index) => (
    <div key={index}>
      <div className="response-message-category">
        <p><strong>{category.category}</strong></p>
      </div>
      <p>{quizData.categoryResponses.categoryResponse[category.category].explanation}</p>
      <ol>
        {quizData.categoryResponses.categoryResponse[category.category].suggestions.map((suggestion, index) => (
          <li key={index}>{suggestion}</li>
        ))}
      </ol>
    </div>
  ))}
  <p>{quizData.categoryResponses.lastResponse} 
    <a href={quizData.categoryResponses.lastResponseLink} target="_blank" rel="noopener noreferrer">Shift the Way You Lead.</a>
  </p>



</div>

    </div>
  );
}

export default Results;
