import React from 'react';
import './styles.css'; // Ensure styles are imported
import { getSessionEventId } from './utils/metaPixelUtils';

//<h2 className="question-title">{question.title}</h2>

function Question({ question, onAnswerSelect }) {
  const handleAnswerSelect = (value, category) => {
    // Track the answer with Meta Pixel
    try {
      if (window.fbq) {
        window.fbq('track', 'ViewContent', {
          content_name: 'Quiz Question',
          content_category: category,
          status: value,
          eventID: getSessionEventId()
        });
      }
    } catch (error) {
      console.error('Error tracking Meta event:', error);
    }

    // Call the original onAnswerSelect handler
    onAnswerSelect(value, category);
  };

  return (
    <div className="quiz-container">
      <div>
        
        <p className="question-prompt">{question.prompt}</p>
        <div>
          {Array.from({ length: question.range.max }, (_, i) => i + 1).map(value => (
            <button
              key={value}
              className="btn answer-btn"
              onClick={() => handleAnswerSelect(value, question.category)}>
              {value}
            </button>
          ))}
        </div>
      </div>
      <div className="value-descriptions">
        <div className="min-value">
          <strong>{question.range.min} being:</strong>
          <p>{question.extremeValues.minValueAnswer}</p>
        </div>
        <div className="max-value">
          <strong>{question.range.max} being:</strong>
          <p>{question.extremeValues.maxValueAnswer}</p>
        </div>
      </div>
    </div>
  );
}

export default Question;
